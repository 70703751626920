import React from 'react';
import { Container, Button } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tdsvideomodal from './Tdsvideomodal';
import {ListGroup, ListGroupItem} from 'reactstrap';
import Icofont from 'react-icofont';

import './Tdsbanner.css';
class Tdsbanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: {
                    nav: false,
                    dots: true,
                },
                767: {
                    nav: true
                },

            },
        }

    }

    render() {
        return (

            <section className="banner">

                <OwlCarousel
                    className="banner-owl owl-theme"
                    loop={true}
                    margin={0}
                    autoplay={true}
                    autoplayTimeout={5000}
                    smartSpeed={1500}
                    nav={true}
                    items="1"
                    responsive={this.state.responsive}
                    dots={false} >
                    <div className="item">
                        <div className="banner-one banner-img">
                            <Container>
                                <div className="banner-text text-center">
                                    <h1 ><span>CUSTOM </span> GLASS & MIRRORS CORP</h1>
                                    <p>We are considered a full service glass  company  and we take great pride in providing high quality materials and services to meet your glass and mirror needs.</p>
                                    <div className="d-flex justify-content-center">
                                        {/*<Button color="primary mr-3">Get Started</Button>
                                        <Tdsvideomodal></Tdsvideomodal>*/}
                                        <ListGroup className="social-icon">
                                            <ListGroupItem tag="a" href="https://www.instagram.com/customglassandmirrorscorp?igshid=YzVkODRmOTdmMw%3D%3D&utm_source=qr">  <Icofont icon="icofont-instagram" /></ListGroupItem>
                                            <ListGroupItem tag="a" href="https://www.facebook.com/profile.php?id=61551045596020&mibextid=LQQJ4d">  <Icofont icon="icofont-facebook" /></ListGroupItem>
                                        </ListGroup>
                                    </div>

                                </div>
                            </Container>
                        </div>
                    </div>
                    <div className="item">
                        <div className="banner-two banner-img">
                            <Container>
                                <div className="banner-text text-center">
                                    <h1 ><span>CUSTOM </span> GLASS & MIRRORS CORP</h1>
                                    <p>Our services include storefronts, glass shower enclosures, commercial and residential doors, windows, skylights, glass railings, high temperature glass, bullet-proof glass, walls of mirrors, and hanging mirrors.</p>
                                    <div className="d-flex justify-content-center">
                                        {/*<Button color="primary mr-3">Get Started</Button>
                                        <Tdsvideomodal></Tdsvideomodal>*/}
                                        <ListGroup className="social-icon">
                                            <ListGroupItem tag="a" href="https://www.instagram.com/customglassandmirrorscorp?igshid=YzVkODRmOTdmMw%3D%3D&utm_source=qr">  <Icofont icon="icofont-instagram" /></ListGroupItem>
                                            <ListGroupItem tag="a" href="https://www.facebook.com/profile.php?id=61551045596020&mibextid=LQQJ4d">  <Icofont icon="icofont-facebook" /></ListGroupItem>
                                        </ListGroup>
                                    </div>
                                </div>
                            </Container>
                        </div>

                    </div>
                </OwlCarousel>
            </section>
        );
    }
}


export default Tdsbanner;
